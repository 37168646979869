.homeContainer{
    margin-top: 2rem;
}

.homeLogo{
    justify-content: center;
    text-align: center;
    height:120px;
    margin: 3rem;
}

.euLogo{
    justify-content: center;
    text-align: center;
    height:60px;
    margin: 5rem;
}

.homeh1{
    color: #2994B1;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: .5rem;
}

.buttonContainerHome{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 14rem;
}

button.buttonHome{
    color: #2994B1;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid #2994B1;
}

button.footerButton{
    padding: 9px;
    justify-content: center;
    width: 30%;
    text-align: center;
    color: #2994B1;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid #2994B1;
    margin-top: 1rem; 
}
button.footerButton:hover{
    color: white;
    background-color: #2994B1;
}

button.button{
    padding: 9px;
    color: #2994B1;
    font-size: 12px;
    font-weight: 400;
    line-height: .9;
    border-radius: 10px;
    border: 2px solid #2994B1;
}
button.button:hover{
    color: white;
    background-color: #2994B1;
}

.links{
    color: #2994B1;
    text-decoration: none;
    padding:15px;
    font-weight: 500;
    font-size: medium;
    border-radius: 10px;
    cursor: pointer;
}
.headerContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 6px;
    border-bottom: solid 2px #55B041;
    align-items: center;
}
.headerContainer .links:hover {
    background-color: #2994B1;
    color: white;
}
.footerContainer {
  background-color: black;
  display: flex;
  margin-top: 2.4rem;
  flex-direction: row;
  justify-items: center;
  justify-content: space-evenly;
  padding: 5px;
  bottom: 0;
  border-top: solid 3px #55B041;
}
.footerContainer a.links:hover, .footerContainer svg:hover {
     color: white;
}
.firstCol { 
    padding-right: 2rem;
}
.icons{
    color: #2994B1;
}
.pageLinks{
    align-items: center;
    display: flex;
}

.logo{
    height:50px;
    padding: 4px;
}

li{
    flex-direction: column;
    margin: 10px
}

.headerMobile{
    display: none;
}

.errorHandler{
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
}

h6.tripInfoHeaderText{
    margin-top:1rem;
     color:#2994B1 
}

.tripDetailInfo{
    color:#448c34;
}

p.modeText{
    color:#448c34;
    width: fit-content;
    position: relative;
    width: 95%;
    margin: .5rem 0;
    border-bottom: 1px solid grey;
    padding: 0.3rem;
    line-height: 1.8;
}

.modeText a.links{
    padding: 0;
    position: absolute;
    top: 2px;
    right: 2rem;
    line-height: 1;
}
.profileContainer{
    padding: 2rem;
    width: 60%;
}
.profileHeight{
    height: calc( 100vh - 200px );
}

.responsiveHeight {
    height: calc( 100vh - 130px ); /* 75px top bar + 75px footer + 10px botom padding */
}
  
@media screen and (max-width: 768px) {
  .headerMobile {
    display: block;
  }
  .headerContainer {
    display: none;
  }
  .footerContainer{
    display: block;
  }
  .pageLinks{
    display: grid;
    text-align: center;
  }
  .responsiveHeight{
    height: 400px;
  }
.profileContainer{
    width: 100%;
}
}


